<template>
    <div class="videoHeatBox">
        <div class="videoHeatHeader" v-if="videoList.video_goods">
            <img class="headerImg" :src="videoList.video_goods.image" alt="">
            <div class="headerCountent" v-if="videoList.video_goods">
                <p><a :href="videoList.video_goods.url" target="_blank">{{videoList.video_goods.title}}</a></p>
                <p>
                    <span><b>￥</b>{{videoList.video_goods.final_price}}</span>
                    <span>原价：￥{{videoList.video_goods.max_price}}</span>
                </p>
                <p>
                    <span>抖音浏览量:{{videoList.video_goods.pv_count}}</span>
                    <span>全网销量:{{videoList.video_goods.order_count}}件</span>
                </p>
            </div>
        </div>
        <div v-else>暂无结果</div>
        <div class="main_box">
            <div class="left-item-fan">
                <h4 class="item-half-sub">抖音销量日增量</h4>
                <div class="left-item-content">
                    <div class="fan-tab">
                        <div class="item" :class="{current: fanTabIndex === 0}" @click="toggleFanTab(0)">近7天</div>
                        <div class="item" :class="{current: fanTabIndex === 1}" @click="toggleFanTab(1)">30天</div>
                    </div>
                    <div id="fansGrowth" :style="{width: '1000px', height: '442px'}"></div>
                </div>
            </div>
            <div class="left-item-fan">
                <h4 class="item-half-sub">抖音浏览量日增量</h4>
                <div class="left-item-content">
                    <div class="fan-tab">
                        <div class="item" :class="{current: fanTabIndex1 === 0}" @click="toggleFanTab1(0)">近7天</div>
                        <div class="item" :class="{current: fanTabIndex1 === 1}" @click="toggleFanTab1(1)">30天</div>
                    </div>
                    <div id="fansGrowth1" :style="{width: '1000px', height: '442px'}"></div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        props:{
            videoList:{
                type:Object
            }
        },
        data(){
            return {
                videoData: {},
                tableData: [],
                fanTabIndex: 0,
                fanTabIndex1: 0,
                sales_7: [],
                sales_30: [],
                view_7: [],
                view_30: [],
            }
        },
        created() {
        },
        methods:{
            updateData() {
                this.fanTabIndex = 0;
                this.fanTabIndex1 =0;
                this.sales_7 = [];
                this.sales_30 = [];
                this.view_7 = [];
                this.view_30 = [];
                let obj = {};
                let objTow = {};
                let objThree = {};
                let bojFour = {};
                if(this.videoList.goods_chart_data.data7){
                    for(let i = 0; i<this.videoList.goods_chart_data.data7.length; i++){
                        if(this.videoList.goods_chart_data.data7[i].order_count){
                            let time = this.dateFormat(this.videoList.goods_chart_data.data7[i].update_time).substring(5,10);
                            obj = {'order_count':this.videoList.goods_chart_data.data7[i].order_count,'update_time':time};
                            this.sales_7.push(obj)
                        }
                        if(this.videoList.goods_chart_data.data7[i].pv_count){
                            let timeTwo = this.dateFormat(this.videoList.goods_chart_data.data7[i].update_time).substring(5,10);
                            objTow = {'pv_count':this.videoList.goods_chart_data.data7[i].pv_count,'update_time':timeTwo};
                            this.view_7.push(objTow)
                        }
                    }
                    this.salsFun(this.sales_7);
                    this.viewsFun(this.view_7);
                }
                if(this.videoList.goods_chart_data.data30){
                    for(let j = 0; j<this.videoList.goods_chart_data.data30.length; j++){
                        if(this.videoList.goods_chart_data.data30[j].order_count){
                            let times = this.dateFormat(this.videoList.goods_chart_data.data30[j].update_time).substring(5,10);
                            objThree = {'order_count':this.videoList.goods_chart_data.data30[j].order_count,'update_time':times};
                            this.sales_30.push(objThree)
                        }
                        if(this.videoList.goods_chart_data.data30[j].pv_count){
                            let timesTwo = this.dateFormat(this.videoList.goods_chart_data.data30[j].update_time).substring(5,10);
                            bojFour = {'pv_count':this.videoList.goods_chart_data.data30[j].pv_count,'update_time':timesTwo};
                            this.view_30.push(bojFour)
                        }
                    }
                }
                console.log(105,this.sales_7,this.view_7)
            },
            toggleFanTab(index) {
                this.fanTabIndex = index;
                if (this.fanTabIndex == 0) {
                    this.salsFun(this.sales_7);
                } else {
                    this.salsFun(this.sales_30);
                }
            },
            toggleFanTab1(index) {
                this.fanTabIndex1 = index;
                if (this.fanTabIndex1 == 0) {
                    this.viewsFun(this.view_7);
                } else {
                    this.viewsFun(this.view_30);
                }
            },
            salsFun(fansTrendArry) {
                // 时间和数据
                let time = [],
                    data = [];
                for (let i in fansTrendArry) {
                    // 保存时间和数据
                    time.push(fansTrendArry[i].update_time);
                    data.push(fansTrendArry[i].order_count / 1000);
                }
                this.fansGrowth1 = this.$echarts.init(document.getElementById('fansGrowth'));
                this.fansGrowth1.setOption({
                    title: {
                        x: 'center',
                        y: 'bottom',
                        textStyle: { //设置主标题风格
                            Color: '#333333',//设置主标题字体颜色
                            fontSize: 14,
                            fontStyle: 'normal',
                        },
                    },
                    tooltip:{
                      trigger: 'axis',
                      axisPointer:{
                          type:'cross',
                          label:{
                              background:'#6a7985'
                          }
                      }
                    },
                    xAxis: {
                        data: time,
                        //设置网格线颜色
                        boundaryGap: false,
                        axisLine: {
                            show: true,
                            lineStyle: {
                                color: '#999999'
                            }
                        },
                    },
                    yAxis: {
                        type: 'value',
                        splitLine: {
                            show: true,
                            lineStyle: {
                                color: ['#f1f1f1'],
                            }
                        },
                        //隐藏刻度线
                        axisTick: {
                            show: false
                        },
                        axisLine: {
                            show: false,
                            lineStyle: {
                                color: '#999999'
                            }
                        },
                        axisLabel: {
                            fontSize: '14',
                            marginTop: '35px',
                            formatter: '{value}W',
                            show: true,
                        },
                    },
                    series: [{
                        data: data,
                        type: 'line',
                        lineStyle: {
                            color: '#20C997',
                            width: 3,
                        },
                        areaStyle: {
                            color: '#d8f6ec'
                        },
                        smooth: true,
                        symbol: "none",
                    }]
                }, true);
            },
            viewsFun(fansTrendArry) {
                // 时间和数据
                let time = [],
                    data = [];
                for (let i in fansTrendArry) {
                    // 保存时间和数据
                    time.push(fansTrendArry[i].update_time);
                    data.push(fansTrendArry[i].pv_count / 1000);
                }
                this.fansGrowth = this.$echarts.init(document.getElementById('fansGrowth1'));
                this.fansGrowth.setOption({
                    title: {
                        x: 'center',
                        y: 'bottom',
                        textStyle: { //设置主标题风格
                            Color: '#333333',//设置主标题字体颜色
                            fontSize: 14,
                            fontStyle: 'normal',
                        },
                    },
                    tooltip:{
                        trigger: 'axis',
                        axisPointer:{
                            type:'cross',
                            label:{
                                background:'#6a7985'
                            }
                        }
                    },
                    xAxis: {
                        data: time,
                        //设置网格线颜色
                        boundaryGap: false,
                        axisLine: {
                            show: true,
                            lineStyle: {
                                color: '#999999'
                            }
                        },
                    },
                    yAxis: {
                        type: 'value',
                        splitLine: {
                            show: true,
                            lineStyle: {
                                color: ['#f1f1f1'],
                            }
                        },
                        //隐藏刻度线
                        axisTick: {
                            show: false
                        },
                        axisLine: {
                            show: false,
                            lineStyle: {
                                color: '#999999'
                            }
                        },
                        axisLabel: {
                            fontSize: '14',
                            marginTop: '35px',
                            formatter: '{value}W',
                            show: true,
                        },
                    },
                    series: [{
                        data: data,
                        type: 'line',
                        lineStyle: {
                            color: '#20C997',
                            width: 3,
                        },
                        areaStyle: {
                            color: '#d8f6ec'
                        },
                        smooth: true,
                        symbol: "none",
                    }]
                }, true);
            },
            dateFormat:function(time) {
                var date=new Date(time*1000);
                var year=date.getFullYear();
                var month= date.getMonth()+1<10 ? "0"+(date.getMonth()+1) : date.getMonth()+1;
                var day=date.getDate()<10 ? "0"+date.getDate() : date.getDate();
                var hours=date.getHours()<10 ? "0"+date.getHours() : date.getHours();
                var minutes=date.getMinutes()<10 ? "0"+date.getMinutes() : date.getMinutes();
                var seconds=date.getSeconds()<10 ? "0"+date.getSeconds() : date.getSeconds();
                // 拼接
                return year+"-"+month+"-"+day+" "+hours+":"+minutes+":"+seconds;
            },
        }
    }
</script>
<style scoped lang="scss">
    .videoHeatBox{
        display: flex;
        flex-direction: column;
        height: 100%;
        .videoHeatHeader{
            border-bottom: 1px solid #EAEAEA;
            margin-left: 25px;
            display: flex;
            .headerImg{
                width: 110px;
                height: 158px;
                margin-bottom: 24px;
            }
            .headerCountent{
                margin-left: 23px;
                p{
                    line-height: 1;
                    margin: 0;
                }
                p:nth-child(1){
                    a{
                        color: #333333;
                        font-size: 18px;
                        font-weight: bold;
                    }
                    margin-top: 10px;
                }
                p:nth-child(2){
                    margin-top: 23px;
                    span:nth-child(1){
                        font-size: 32px;
                        color: #FF0000;
                        b{
                            font-size: 16px;
                        }
                    }
                    span:nth-child(2){
                        margin-left: 21px;
                        font-size: 16px;
                        color: #666666;
                        text-decoration:line-through;
                    }
                }
                p:nth-child(3){
                    margin-top: 23px;
                    font-size: 14px;
                    color: #999999;
                    span:nth-child(2){
                        margin-left: 24px;
                    }
                }
            }
        }
        .main_box {
            padding-top: 20px;
            margin-left: 40px;

            .left-item-fan {
                margin-bottom: 20px;

                .item-half-sub {
                    font-size: 16px;
                    line-height: 24px;
                }

                .left-item-content {
                    background: #fff;
                    margin-top: 15px;
                    padding: 15px;

                    .fan-tab {
                        text-align: right;
                        padding-right: 30px;
                        color: #999;
                        line-height: 1;

                        .item {
                            display: inline-block;
                            padding: 0 10px;
                            border-right: 1px solid #999;
                            cursor: pointer;

                            &:hover.item {
                                color: #20C997;
                            }

                            &:current {
                                color: #20C997;
                            }

                            &:last-of-type {
                                border-right: 0;
                                padding: 0 0 0 10px;
                            }
                        }
                    }
                }
            }
        }
        .current {
            color: #20C997;
        }

    }
</style>