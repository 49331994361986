<template>
    <div class="DsVideoDetail">
        <div style="width: 80%">
        <div class="DsVideoDetail-header" v-if="videoDetailList.video_details">
            <img :src="videoDetailList.video_details.aweme.aweme_cover||''" alt="" class="headerCover">
            <div class="headerCountent">
                <p class="headerCountentTitle">{{videoDetailList.video_details.aweme.aweme_title}}</p>
                <div class="headerCountentLike">
                    <i class="iconfont">&#xe62a;</i><span>{{videoDetailList.video_details.aweme.digg_count}}</span>
                    <i style="margin-left: 17px" class="iconfont">&#xe6e7;</i><span>{{videoDetailList.video_details.aweme.share_count}}</span>
                    <i style="margin-left: 17px" class="iconfont">&#xe664;</i><span>{{videoDetailList.video_details.aweme.comment_count}}</span>
                </div>
                <div class="headerCountenHot">
                    <span>热词:</span>
                    <span v-if="hotWords.length>0" class="hotWords" v-for="item in hotWords" :key="item">{{item}}</span>
                    <span v-if="!hotWords.length">暂无</span>
                </div>
                <p class="issueTime">发布时间:{{videoDetailList.video_details.aweme.update_time}}</p>
            </div>
            <div class="headerBtn"><a target="_blank" style="color: #ffffff" :href="videoDetailList.video_details.aweme.aweme_url">播放视频</a></div>
        </div>
        <div class="tab-box">
            <el-tabs type="card" v-model="activeName" @tab-click="handleClick">
                <el-tab-pane label="视频详情" name="videoDetails">
                    <videoDetail ref="videoDetailsIns" :videoList = "videoDetailList" />
                </el-tab-pane>
                <el-tab-pane label="视频观众分析" name="videoAnalyzes">
                    <videoAnalyze ref="videoAnalyzeIns" :videoList = "videoDetailList" />
                </el-tab-pane>
                <el-tab-pane label="商品分析" name="goodsAnalyzes">
                    <goodsAnalyze @func="getTakeGoodsVideoInfo" ref="goodsAnalyzeIns" :videoList = "videoDetailList" />
                </el-tab-pane>
                <el-tab-pane label="电商视频热度监控" name="videoHeats">
                    <videoHeat ref="videoHeatIns" :videoList = "videoDetailList" />
                </el-tab-pane>
            </el-tabs>
        </div>
        </div>
        <!--右侧帮助中心-->
        <RightHelpList></RightHelpList>
    </div>
</template>
<script>
    import videoDetail from './../../../components/StudentDsVideo/videoDetail'
    import videoAnalyze from './../../../components/StudentDsVideo/videoAnalyze'
    import goodsAnalyze from './../../../components/StudentDsVideo/goodsAnalyze'
    import videoHeat from './../../../components/StudentDsVideo/videoHeat'
    import RightHelpList from '@/components/helpCenter/RightHelpList.vue'
    export default {
        name:'DsVideoDetail',
        components:{
            videoDetail,
            videoAnalyze,
            goodsAnalyze,
            videoHeat,
            RightHelpList
        },
        data(){
            return {
                videoDetailList:{},
                hotWords:[],
                activeName:'videoDetails',
                //分页
                studentPages: {
                    currentPageNum: 1,
                    eachPageNum: 10,
                    total: 0
                },
            }
        },
        created() {
            this.getTakeGoodsVideoInfo();
        },
        methods: {
            //获取详情数据
            getTakeGoodsVideoInfo(data){
                if(data){
                    let param = {
                        aweme_id:data
                    };
                    this.$httpStudent.axiosGetBy(this.$api.hotPeopleVideoDetails,param,(res)=>{
                        if(res.code === 200){
                            this.videoDetailList = res.data;
                            this.hotWords = res.data.video_details.aweme.hot_words;
                            this.activeName = 'videoDetails';
                        } else {
                            console.log('请求失败')
                        }
                    })
                } else {
                    if(this.$route.query.name == 'goodsAnalyzes'){
                        this.activeName = 'goodsAnalyzes';
                    }
                    let options = JSON.parse(this.$route.query.options);
                    let id = options.video_id;
                    let param = {
                        aweme_id:id
                    };
                    this.$httpStudent.axiosGetBy(this.$api.hotPeopleVideoDetails,param,(res)=>{
                        if(res.code === 200){
                            this.videoDetailList = res.data;
                            this.hotWords = res.data.video_details.aweme.hot_words;
                        } else {
                            console.log('请求失败')
                        }
                    })
                }
            },
            handleClick(tab, event) {
                if (this.activeName === 'videoAnalyzes') {
                    this.$refs.videoAnalyzeIns.updateData();
                } else if(this.activeName === 'videoDetails'){
                    this.$refs.videoDetailsIns.updateData();
                } else if(this.activeName === 'videoHeats'){
                    this.$refs.videoHeatIns.updateData();
                } else if(this.activeName === 'goodsAnalyzes'){
                    this.$refs.goodsAnalyzeIns.updateData();
                }

            },
        }
    }
</script>
<style scoped lang="scss">
    .DsVideoDetail{
        display: flex;
        /*flex-direction: column;*/
        height: 100%;
        width: 100%;
        .DsVideoDetail-header{
            position: relative;
            display: flex;
            background: #ffffff;
            padding: 20px 24px;
            .headerCover{
                width: 105px;
                height: 130px;
            }
            .headerCountent{
                overflow: hidden;
                margin-left: 26px;
                .headerCountentTitle{
                    -webkit-line-clamp:2;
                    display: -webkit-box;
                    -webkit-box-orient:vertical;
                    overflow:hidden;
                    text-overflow: ellipsis;
                    color: #333333;
                    font-size: 16px;
                    width: 432px;
                    height: 44px;
                    margin: 0;
                }
                .headerCountentLike{
                    margin-top: 8px;
                    display: flex;
                    align-items: center;
                    i{
                        font-size: 20px;
                        color: #333333;
                        line-height: 21px;
                    }
                    span{
                        color: #333333;
                        font-size: 16px;
                        font-weight: 400;
                        margin-left: 6px;

                    }
                }
                .headerCountenHot{
                    overflow: hidden;
                    span{
                        margin-top: 12px;
                    }
                    span:nth-child(1){
                        color: #999999;
                        font-size: 12px;
                    }
                    .hotWords{
                        display: inline-block;
                        color: #333333;
                        font-size: 12px;
                        padding: 6px 16px;
                        background: #F4F4F4;
                        border-radius: 12px;
                        line-height: 1;
                        font-weight:400;
                        margin-left: 15px;
                    }
                }
                .issueTime{
                    color: #999999;
                    font-size: 12px;
                    line-height: 1;
                    margin-top: 10px;
                    margin-bottom: 0;
                }
            }
            .headerBtn{
                width: 106px;
                height: 32px;
                background:linear-gradient(85deg,#FF0000,#FF6060,);
                border-radius:2px;
                color: #ffffff;
                text-align: center;
                line-height: 32px;
                font-size: 14px;
                position: absolute;
                right: 2%;
                top: 9%;
            }
        }
        .tab-box{
            display: flex;
            width: 100%;
            height: 100%;
            margin-top: 24px;
            ::v-deep .el-tabs {
                height: 100%;
                width: 100%;
                box-shadow: none;
                border: none;
                display: -ms-flexbox;
                /*display: flex;*/
                /*-ms-flex-direction: column;*/
                /*flex-direction: column;*/
                background: transparent;
            }
            ::v-deep .el-tabs__header {
                border-bottom: none;
                margin: 0;
            }
            ::v-deep .el-tabs__content {
                /*height: 1%;*/
                /*flex: 1;*/
                background: #fff;
                padding-top: 20px;
                margin-bottom: 20px;
            }
            ::v-deep .el-tabs__header .is-active {
                background: #fff;
                font-size: 16px;
                font-weight: 400;
                color: #0824ce;
                border-radius: 6px 6px 0 0;
            }
            ::v-deep .el-tabs__item {
                border: none;
                height: 52px;
                line-height: 52px;
            }

            ::v-deep .el-tabs--card > .el-tabs__header .el-tabs__nav {
                border: none;
            }

            ::v-deep .el-tab-pane {
                height: 100%;
            }
        }
    }
</style>